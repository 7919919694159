@value bannerHeight: 52px;

.Banner {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;

  height: bannerHeight;

  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: stretch;

  transition: top 0.5s ease-in;
}

.Banner.hidden {
  top: calc(-1 * bannerHeight);
}

.Banner.error {
  background: rgb(222, 53, 11);
  color: white;
}

.Banner.warning {
  background: rgb(255, 171, 0);
  color: black;
}

.Banner.info {
  background: rgb(66, 82, 110);
  color: white;
}

.ErrorNav {
  align-self: center;
  margin: 0 8px;
}

.ErrorNav > i:nth-child(2) {
  margin-left: -8px;
}

.NavNumbers {
  display: inline-block;
  position: relative;
  top: 1px;
}

.MessageContent {
  flex: 1 1 auto;
  overflow: hidden;
  padding: 8px 16px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.ActionButton {
  align-self: center;
  margin-right: 8px;
}

.ExpandedView {
  position: absolute;
  top: bannerHeight;
  right: 0;
  left: 0;

  padding: 16px;
}

.Banner.error .ExpandedView {
  background: hsl(12, 100%, 95%);
  color: rgb(39, 0, 0);
  border-bottom: 1px solid rgb(192, 149, 138);
}

.ExpandedView .ErrorItem {
  margin-bottom: 0.5em;
  margin-left: 1em;
  list-style-type: decimal !important;
}

.ExpandedView .ErrorItem:last-child {
  margin-bottom: 0;
}
