::placeholder {
  color: #aaa !important;
  opacity: 1;
}

::-ms-input-placeholder {
  color: #aaa !important;
}

body {
  color: #424242;
  background: #f7f7f7;
  font-size: 18px;
}

a {
  cursor: pointer;
}

#root {
  height: 100%;
}

.ui.dimmer {
  height: 100%;
  z-index: 2018;
  background-color: rgba(0, 0, 0, 0.33);
}

.ui.popup {
  z-index: 9999 !important;
}

@media (max-width: 1024px) {
  #product {
    top: -1rem;
    width: 100%;
    border-radius: 0;
  }
  #product .content {
    padding-top: 50px !important;
  }
  #product > .close {
    top: 0.5rem;
  }
}

@media (max-width: 640px) {
}

@media (max-width: 480px) {
}

/* .ui.popup.inverted,
.ui.popup.inverted:before {
  background: #2196f3 !important;
} */

.ui.form .fields .field .ui.input input {
  width: 100% !important;
}
